import React from 'react';
import logo from './logo.svg';
import wipmobile from './work-in-progress-mobile.svg';
import wip from './work-in-progress.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <nav className="top-nav" role="navigation">
          <div className="top-nav-link">
            <a>Apps</a>
          </div>
          <div className="top-nav-link">
            <a>Games</a>
          </div>
          <div className="top-nav-home">
            <span className="top-nav-home-name">PIX'L</span><a><img src={logo} className="top-nav-logo" alt="Pix'l Works Home"/></a><span className="top-nav-home-name">WORKS</span>
          </div>
          <div className="top-nav-link">
            <a>Talks</a>
          </div>
          <div className="top-nav-link">
            <a>Blog</a>
          </div>
        </nav>
      </header>
      <main className="App-content">
        <img src={wipmobile} id="wip-logo-m" alt="logo" />
        <img src={wip} id="wip-logo" alt="logo" />
        {/* <img
          srcSet={ wipmobile + " 300w," + wip + " 650w" }
          // sizes="(max-width: 600px) 300px, 700px"
          sizes="(min-width: 900px) 100vw, 50vw"
          src={wipmobile}
          alt="PARDON MY PIXEL DUST, SITE UNDER CONSTRUCTION"
            /> */}
      </main>
    </div>
  );
}

export default App;
